<template>
	<div class="animated fadeIn">
		<b-card>
			<div class="display-table full-width mb-2">
				<strong class="card-title">{{$route.meta.label}}</strong>
				<router-link :to="'/'+config.uri+'/add'" 
					class="btn btn-primary display-table-cell pull-right rounded">
					<i class="fa fa-plus"/> Add Page
				</router-link>
			</div>
			<b-row>
				<ResponseAlert ref="response" />
				<div class="col-sm-12 mb-10">
					<div class="col-sm-3 pull-right no-padding">
						<div class="input-group">
							<input type="text" placeholder="Search..." v-model="config.search.key" v-on:keyup.enter="get()"
								class="form-control form-control-sm" />
							<div class="input-group-prepend">
								<div class="input-group-text" v-on:click="get()">
									<i class="fa fa-search"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
				<b-col sm="12">
					<div class="table-responsive">
						<table class="table b-table">
							<thead>
								<tr>
									<th v-for="(row, key) in config.headers" :width="row.width" :key="key"
										:class="row.class">
										<a v-if="row.value" class="sort dark-font" v-on:click="sort(row.value)"
											href="javascript:void(0)">
											{{row.title}}
											<i :id="row.value" class="fa fa-sort"></i>
										</a>
										<a v-else class="sort dark-font" :id="row.value" href="javascript:void(0)">
											{{row.title}}
										</a>
									</th>
								</tr>
							</thead>
							<tbody>
								<template v-if="config.total_data">
									<tr v-for="(row, key) in config.rows" :key="key">
										<td>{{row.title}}</td>
										<td>
											<span :class="'badge badge-' + getClassStatus(row.status)">
												{{row.status}}
											</span>
										</td>
										<td align="center">
											<router-link :to='`/${config.uri}/${row.id}`' title="Detail"
												class="btn-action">
												<i class="fa fa-eye"></i>
											</router-link>
											<router-link :to='`/${config.uri}/edit/${row.id}`' title="Edit"
												class="btn-action">
												<i class="fa fa-pencil"></i>
											</router-link>
											<button @click="destroy(row.id)" title="Delete" class="btn-action">
												<i class="fa fa-trash"></i>
											</button>
										</td>
									</tr>
								</template>
								<tr v-if="!config.total_data">
									<td :colspan="config.headers.length" align="center"> No data available. </td>
								</tr>
							</tbody>
							<tfoot v-if="config.total_data">
								<tr>
									<td :colspan="config.headers.length" class="no-padding">
										<div class="pull-left table-information mt-25">
											<p>Showing {{config.page}} to {{config.total_page}} of {{config.total_data}}
												entries</p>
										</div>
										<div class="pull-right pagination mt-25">
											<b-pagination @change="gotoPage" :total-rows="config.total_data"
												v-model="config.page" :per-page="config.per_page">
											</b-pagination>
										</div>
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</b-col>
			</b-row>
		</b-card>
	</div>
</template>
<script>
	import table from '@/utils/table.js'
	import api from '@/utils/api.js'
	import ResponseAlert from '@/components/response_alert';
	import { getClassStatus } from '@/utils/helpers';
	export default {
		name: 'ListPage',
		components: {
			ResponseAlert,
		},
		data() {
			return {
				config: {
					uri: this.$route.meta.permission.link,
					api: api.page,
					rows: [],
					total_data: 0,
					total_page: 0,
					per_page: 10,
					page: 1,
					orderby: "id",
					sort: "desc",
					search: {
						key: ""
					},
					headers: [{
						title: "Title",
						value: "title",
						align: "text-left",
						width: "50%",
					}, {
						title: "Status",
						value: "status",
						align: "text-left",
						width: "25%",
					}, {
						title: "Action",
						value: false,
						class: "text-center",
						width: "25%",
					}],
				},
			}
		},
		created() {
			const _ = this;
			_.get();
		},
		methods: {
			get() {
				table.get(this.config)
			},
			sort(orderby) {
				table.sort(this.config, orderby)
			},
			gotoPage(page) {
				table.gotoPage(this.config, page)
			},
			getClassStatus(status) {
				return getClassStatus(status);
			},
			destroy(id) {
				const _ = this;
				_.$dialog.confirm('Are you sure want to delete this data?', {
						"okText": "Yes",
						"cancelText": "No"
					})
					.then(() => {
						_.$axios.delete(`${api.page}/${id}`)
						.then(res => {
							_.$refs.response.setResponse(res.data.message, 'success');
							_.get()
						}).catch(error => {
							_.$refs.response.setResponse(error.response.data.message, 'danger')	
						})
					})
			},
		},
	}
</script>